body {
    height: 100%;
    margin: 0;
    background-color: black;
}
form {
    position: absolute;
    display: none;
}
form input, form select {
    font-family: 'Nova Script';
    color: white;
    padding: 0.3% 0.5%;
    background-color: #1a1a1a;
    border: 2px solid #777;
    border-radius: 6px;
    outline: none;
}
#playername {
    width: 30%;
    margin: 23% 0 0 60%;
}
